@import "../../styles/mixins";

.header {
  @include flex(flex-start);
  gap: 16px;

  &.vertical {
    display: grid;
  }

  @include mobile {
    display: grid;
  }
}

.moniker {
  font-size: 24px;
}

.footer {
  border-top: 1px solid var(--card-border);
  margin-top: 12px;
  padding-top: 20px;

  h2 {
    font-size: var(--font-size-small);
  }
}
