@import "../../styles/mixins";

.component {
  @include desktop {
    @include flex(flex-start);
    gap: 12px;
  }
}

.message {
  font-size: 16px;
}
