@import "../../../styles/mixins";

.title {
  font-size: var(--font-size-small);
}

.numbers {
  gap: 36px;

  @include desktop {
    grid-template-columns: repeat(2, 1fr);
  }

  .item {
    gap: 20x;
  }

  .value {
    font-size: 28px;
  }
}
