@import "../../styles/mixins";

.list {
  background: var(--card-bg);
  border: var(--border-width) solid var(--card-border);
  border-radius: var(--border-radius);
  overflow: hidden;
  @include mobile {
    border-radius: 0;
    &.isModal {
      border: none;
    }
  }
}

.link {
  @include flex(space-between);

  color: var(--text);
  font-weight: var(--bold);
  padding: var(--card-padding-small) var(--card-padding);
  transition: background var(--transition);
  width: 100%;

  svg {
    width: 24px;
    height: 24px;
    fill: currentcolor;
  }

  &:not(:first-child) {
    border-top: var(--border-width) solid var(--card-border);
  }

  &:hover {
    background: var(--bg-muted);
    text-decoration: none;
  }

  @include mobile {
    padding: 0 24px;
    height: 64px;
    font-size: 16px;
    &:hover {
      background: inherit;
    }
  }
}

.action {
  color: var(--button-primary-bg);
  svg {
    margin-left: 4px;
    width: 12px;
  }
  button {
    vertical-align: middle;
  }
}
