@import "../../styles/mixins";

.asset {
  @include flex(space-between, center);
  margin: 0;
  header {
    flex: 1;
    width: 100%;
    h1 {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.wrapper {
  @include flex-column(space-between, flex-start);
  width: 100%;
}

.title {
  @include flex(flex-start, center);
  width: 100%;
  img {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
}

.detailsContainer {
  @include mobile {
    flex: 1;
    @include flex(space-between, center);
  }
}

.symbol,
.amount {
  font-size: 16px;
}

.amount {
  font-weight: var(--normal);
}

.amountContainer {
  @include mobile {
    margin-right: 12px;
    text-align: right;
  }
}

.value {
  color: var(--text-muted);
  font-size: var(--font-size-small);
}
