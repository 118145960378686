@import "../../styles/mixins";

.item {
  @include flex;

  gap: 12px;
  padding: 20px 0;

  &:not(.compact) {
    flex-direction: column;
  }

  &.isGroup {
    @include mobile {
      display: grid;
      grid-template-areas:
        "image image"
        "name name"
        "view send";
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
      column-gap: 0;
      padding: 20px;
      background: var(--card-bg);
      border: 1px solid var(--card-border);
      border-radius: 8px;
      &:not(:first-child) {
        margin-top: 20px;
      }

      & > button:nth-child(3) {
        grid-area: view;
      }
      & > a {
        border-left: 1px solid var(--card-border);
        grid-area: send;
      }
    }
  }
}

.compact {
  @include flex(flex-start);

  &:not(:first-child) {
    border-top: 1px solid var(--card-border);
  }
}

.image {
  border-radius: 4px;
  cursor: zoom-in;
  overflow: hidden;
  @include mobile {
    grid-area: image;
    img {
      width: 80%;
      height: 80%;
    }
  }
}

.large {
  border-radius: var(--border-radius);
  width: 100%;
  height: auto;
}

.name {
  flex: 1;
  @include mobile {
    grid-area: name;
    padding-bottom: 16px;

    div {
      font-size: 12px;
      color: var(--text-muted);
    }
  }
}
@include mobile {
  .isGroup {
    .name {
      border-bottom: 1px solid var(--card-border);
    }
  }
}

/* placeholder */
.placeholder {
  content: "";
  background: var(--bg-muted);
}
