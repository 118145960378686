@import "../../styles/mixins";

.screen {
  height: 100vh;
  background-color: var(--bg);
  color: var(--text);
}
.page {
  margin-top: -3px;
  height: calc(100vh - var(--header-height) - var(--mobile-tab-height));
  &.inCard {
    height: 100%;
  }
}
