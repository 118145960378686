@import "../../styles/mixins";

.overlay {
  @include flex;

  background: var(--bg);
  color: var(--text);
  text-align: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
