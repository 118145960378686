@import "../../styles/mixins";

.fab {
  position: absolute;
  right: 10px;
  bottom: 74px;
  width: 52px;
  height: 52px;
  fill: var(--button-primary-text);
  background-color: var(--chart);
  border-radius: 50%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}
