@import "../../../styles/mixins";

.link {
  @include flex;
  gap: 4px;
  color: var(--text);

  &:hover {
    text-decoration: none;
  }
}
