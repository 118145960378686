@import "../../styles/mixins";

.flex {
  @include flex;
}

.column {
  @include flex-column;
}

.inline {
  @include inline-flex;
}

/* align */
.start {
  justify-content: flex-start;
}

.end {
  justify-content: flex-end;
}

.wrap {
  flex-wrap: wrap;
}
